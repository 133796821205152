import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../assets/css/404.css"
import http404 from "../assets/images/http-404.svg"

const NotFoundPage = (props) => {
  return (
    <Layout
      navigate={props.navigate}
      categories={props.data.allWcProductsCategories || {edges: []}}>
      <SEO title="404: Not found" />
      <div className="page-404 center ">
      <img src={http404} alt="404 not found" />
      <p>Oops! Cette page n'existe pas.</p>
      <Link title="home page" to="/" className="btn">Revenir à la page d'accueil</Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    allWcProductsCategories (
      filter : { slug : {nin: ["uncategorized", "non-classe"]} },
      sort: { fields: [count], order: DESC }
    ) {
      edges {
       node {
         id
         name
         slug
         display
         count
         description
         _links {
           self {
             href
           }
         }
         image{
           id
           src
           name
           alt
         }
       }
      }
    }
  }
`
